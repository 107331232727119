<template>
  <div class="wrapper">
    <div class="img_box" @click="goApply">
      <img src="../../assets/img/finance_2/loan_1.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  components:{},
  props:{},
  data(){
    return {
    }
  },
  watch:{},
  computed:{},
  methods:{
    goApply() {
      window.location.href = process.env.VUE_APP_LOGIN_URL;
    },
  },
  created(){},
  mounted(){}
}
</script>
<style scoped>
.wrapper{}

.img_box {
  width: 100%;
}

.img_box img {
  width: 100%;
}
</style>
